// src/Callback.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Callback = () => {
  const location = useLocation();

  const [detail, setDetail] = useState(null);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log('Code received:'+code);
    // Call backend server to exchange authorization code for tokens
    // Example:
    fetch('https://testec2be.projectvinayak.com/exchange-code', {
      method: 'POST',
      body: JSON.stringify({ code }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log('token received');
      console.log(data);
      setDetail(data);
    })
    .catch(error => {
      console.error('Error exchanging authorization code for tokens:', error);
    });
  }, [location]);

  return (
    <div>
      <h1>Processing authorization code...Token exchanged..</h1>
      {detail ? (
        <div>
          <p>check console for tokens</p>
          {/* Render other details as needed */}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Callback;
