// src/Login.js
import React from 'react';
import awsConfig from './aws-exports';

const Login = () => {
  const handleLogin = () => {
    console.log(`${awsConfig.LOGIN_URL}`)
    const customState = encodeURIComponent(JSON.stringify({ profileAttribute: 'trainer' }));
    window.location.href = `${awsConfig.LOGIN_URL}${awsConfig.CLIENT_ID}&response_type=code&scope=email+openid+phone+profile+testbe%2Fcustom%3Auserrole&redirect_uri=${awsConfig.REDIRECT_URI}&state=${customState}`;
  };

  return (
    <div>
      <h1>Login</h1>
      <button onClick={handleLogin}>Login with Authorization Server</button>
    </div>
  );
};

export default Login;
